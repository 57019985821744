import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/frau-marker@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 777) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const PinFluid = styled(BackgroundSection)`

z-index: 2;
position: absolute !important;
background-size: contain;
background-position: center right;
background-repeat: no-repeat;
width: calc(94 / 375 * 100vw);
height: calc(94 / 375 * 100vw / 94 * 164);
top: calc(507 / 697 * 100vh);
left: calc(123 / 375 * 100vw);

@media(min-width: 768px){
  display: flex;
  width: calc(259 / 1440 * 100vw);
  height: calc(259 / 1440 * 100vw / 259 * 445);
  top: calc(460 / 810 * 100vh);
  right: calc(217 / 1440 * 100vw);
  left: auto;
}
`

export default PinFluid

