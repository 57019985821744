import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/hand-alt@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1086) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const HandEyeFluid = styled(BackgroundSection)`

z-index: 2;
position: absolute !important;
background-size: contain;
background-position: center left;
background-repeat: no-repeat;
width: calc(116 / 375 * 100vw);
height: calc(116 / 375 * 100vw / 116 * 150);
top: calc(26 / 697 * 100vh + 80px);
left: 0;

@media(min-width: 768px){
  width: calc(315 / 1440 * 100vw);
  height: calc(352 / 1440 * 100vw / 315 * 352);
  top: calc(447 / 810 * 100vh);
}
`

export default HandEyeFluid

