import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/insel-complete@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 2493) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const IslandFluid = styled(BackgroundSection)`

z-index: 1;
position: absolute !important;
background-size: contain;
background-repeat: no-repeat;
width: calc(346 / 375 * 100vw);
height: calc(346 / 375 * 100vw / 346 * 234);
top: calc(137 / 697 * 100vh);
right: calc(15 / 375 * 100vw);

background-position: center right;

@media(min-width: 768px){
  width: calc(831 / 1440 * 100vw);
  height: calc(831 / 1440 * 100vw / 831 * 591);
  top: calc(181 / 810 * 100vh);
  left: calc(502 / 1440 * 100vw);

}
`

export default IslandFluid

