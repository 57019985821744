import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo'
// import BalloonFluid from './backgrounds/Balloon';
import DogFluid from './backgroundsv2/Dog';
import HandEyeFluid from './backgroundsv2/HandEye';
import MuffinFluid from './backgroundsv2/Muffin';
import IslandFluid from './backgroundsv2/Island';
import CloudFluid from './backgroundsv2/Cloud';
import PinFluid from './backgroundsv2/Pin';


// import KreuzFluid from './backgrounds/Heilandskreuz';
// import MapFluid from './backgrounds/Map';/
// import MarktHalleFluid from './backgrounds/Markthalle';
// import ParkFluid from './backgrounds/Park';
// import SportyManFluid from './backgrounds/SportyMan';
import TrumpetFluid from './backgroundsv2/Trumpet';
// import WomanFluid from './backgrounds/Woman';
import { Wrapper, Right, Left, ButtonCallToAction, ButtonMore, HomeContent, SubSubHeadline,  SubHeadline, MainHeadline } from './styled'

const Home = (props) => {

  const {
    pageContext,
    data: {
      wpPage: { cleanTitle, mi_home_hero, mi_home_section, seo, slug },
    },
  } = props;

  const { layout } = pageContext
  const { metaDesc, metaKeywords, metaRobotsNofollow, metaRobotsNoindex, title, opengraphUrl} = seo;
  const { miHomeHeroButtonText, miHomeHeroSubheadline } = mi_home_hero;
  const { miHomeSectionButtonText, miHomeSectionHeadline, miHomeSectionText } = mi_home_section;

  return (
    <>
      <Seo pageLayout={layout} slug={slug} title={cleanTitle} description={metaDesc} keywords={metaKeywords} noFollow={metaRobotsNofollow} noIndex={metaRobotsNoindex} seoTitle={title} url={opengraphUrl} />
      <Wrapper className="home" id="homewrap">
        {/* <MarktHalleFluid /> */}
        {/* <BalloonFluid /> */}
        <IslandFluid />
        <MuffinFluid />
        <HandEyeFluid />
        {/* <KreuzFluid /> */}
        {/* <MapFluid /> */}
        {/* <ParkFluid /> */}
        {/* <SportyManFluid /> */}
        <TrumpetFluid />
       
        <CloudFluid />

        <PinFluid />
        
        {/* <WomanFluid /> */}
        <MainHeadline>{cleanTitle}</MainHeadline>
        <SubHeadline>{miHomeHeroSubheadline}</SubHeadline>
        {/* <ButtonMore to='/erkunden/' title={miHomeHeroButtonText}>{miHomeHeroButtonText}</ButtonMore> */}
        <HomeContent>
          <Left>
            <SubSubHeadline>{miHomeSectionHeadline}</SubSubHeadline>
            {/* <Dog><div className="inner" /></Dog> */}
            <DogFluid />
          </Left>
          <Right>
            <div dangerouslySetInnerHTML={{ __html: miHomeSectionText}} />
            <p>
              <ButtonCallToAction
                title={miHomeSectionButtonText}
                to='/erkunden/'
              >
                {miHomeSectionButtonText}
                <span className="mi-arrow-cta" />
              </ButtonCallToAction>
            </p>
          </Right>
        </HomeContent>
      </Wrapper>
    </>
  )
}

export const homePageQuery = graphql`
query MyQuery {
  wpPage(isFrontPage: {eq: true}) {
    id
    cleanTitle
    databaseId
    slug
    mi_home_hero {
      miHomeHeroButtonText
      miHomeHeroSubheadline
      fieldGroupName
    }
    mi_home_section {
      fieldGroupName
      miHomeSectionButtonText
      miHomeSectionHeadline
      miHomeSectionText
    }
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      title
      opengraphUrl
    }
  }
}
`
export default Home
