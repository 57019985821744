import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/trompeten-frau-cloud@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1572) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const CloudFluid = styled(BackgroundSection)`
display: none;
z-index: 2;
position: absolute !important;
background-size: contain;
background-position: bottom right;
background-repeat: no-repeat;


@media(min-width: 768px){
  display: block;
  width: calc(766 / 1440 * 100vw);
  height: calc(766 / 1440 * 100vw / 766 * 599);
  top: calc(133 / 810 * 100vh);
  left: calc(50 / 1440 * 100vw);
  // background-color: rgba(0,255,255,0.4);
  background-position: bottom left;
}
`

export default CloudFluid

