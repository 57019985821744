import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/trompeten-frau@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 780) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const TrumpetFluid = styled(BackgroundSection)`
// display: none;
z-index: 2;
position: absolute !important;
background-size: contain;
background-position: bottom right;
background-repeat: no-repeat;
width: calc(95 / 375 * 100vw);
height: calc(95 / 375 * 100vw / 95 * 159);
top: calc(243 / 697 * 100vh);
right: calc(59 / 375 * 100vw);

@media(min-width: 768px){
  display: none;
  // width: calc(260 / 1440 * 100vw);
  // height: calc(260 / 1440 * 100vw / 260 * 434);
  // top: calc(296 / 810 * 100vh);
  // left: calc(555 / 1440 * 100vw);
  // background-color: rgba(255,255,0,0.5);
}
`

export default TrumpetFluid

