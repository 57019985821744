import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import arrow_bold from '../../images/other/arrow.svg'
import arrow_cta from '../../images/other/cta-arrow.svg'


import h1_svg from '../../images/start/die-moabiter-insel.svg'
import h2_svg from '../../images/start/entdecke-deine-nachbarschaft.svg'



const Wrapper = styled.div`
    position: relative;
    top: -85px;
    width: 100%;
    @media(min-width: 768px){
      top: -96px;
      width: 100%;
    }
  `

const MainHeadline = styled.h1`
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  top: calc(406 / 697 * 100vh);
  font-family: Rockwell;
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  width: 100%;
  @media(min-width: 768px){
    font-size: 72px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    // top: calc(325 / 810 * 100vh);
    text-indent: -999em;
    width: calc(301 / 1440 * 100vw);
    height: calc(301 / 1440 * 100vw / 301 * 173);
    // top: calc(100vw / 810 * 170);

    /*
    cloud aspect ratio
      766width * 599 height
    */
    top: calc(134 / 810 * 100vh + 1.278* 2vw);
    left: calc(107 / 1440 * 100vw);

    // background-color: rgba(0,255,255,0.4);
    background-position: bottom left;
    background-size: 90% 90%;
    background-position: -10px;
    background-repeat: no-repeat;

    background-image: url(${h1_svg});
    // background: cyan;

  }
`

const SubHeadline = styled.h2`
  margin: 0;
  position: absolute;
  z-index: 3;
  top: calc(448 / 697 * 100vh);
  font-family: SofiaW01;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  width: calc(245 / 375 * 100vw);
  left: 50%;
  transform: translateX(-50%);

  @media(min-width: 768px){
    transform: none;
    // top: calc(320 / 810 * 100vh);
    top: calc(120 / 810 * 100vh + 20px + 1.278* 10vw);
    font-size: 24px;
    line-height: 1.5;


    text-indent: -999em;
    width: calc(473 / 1440 * 100vw);
    height: calc(473 / 1440 * 100vw / 473 * 102);
    // top: calc(100vw / 810 * 170);

    /*
    cloud aspect ratio
      766width * 599 height
    */
    // top: calc(260 / 810 * 100vh + 1.278* 2vw);
    // left: calc(0 / 1440 * 100vw);
    left: 8px;
    // background-color: rgba(0,255,255,0.4);
    background-position: bottom right;
    background-size: 96% 96%;
    // background-position: -20px;
    background-position: 0px;
    background-repeat: no-repeat;

    background-image: url(${h2_svg});
    // background: cyan;

  }
`

const SubSubHeadline = styled.h3`
  font-family: Rockwell;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left; 
  padding: 0 24px 0 0;
  width: calc(327 / 375 * 100vw);
  margin-top: calc(664 / 697 * 100vh );
  position: relative;
  z-index: 3;
  @media(min-width: 768px){
    position: static;
    margin-top: 0;
    font-size: 36px;
    line-height: 1.28;
    width: 361px;
    padding: 0;
  }
`
const HomeContent = styled.div`
  position: relative;
  z-index: 3;
  padding: 0 24px;
  transition: margin 300ms;
  margin-top: calc((480 / 810 * 100vh) + (162 / 375 * 100vw / 162 * 251) + 82px);

  @media(min-width: 768px){
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    margin-top: calc((480 / 810 * 100vh) + (368 / 1440 * 100vw / 368 * 571) + 2px);
    p{
      color: #181d52;
    }
    h3{
      color: #181d52;
    }
  }
`

const ButtonMore = styled(GatsbyLink)`
  z-index: 4;
  border-radius: 6px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.mi_red};
  text-decoration: none;
  color: ${({ theme }) => theme.mi_white};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  padding: 12px 32px 14px 14px;
  background-image: url(${arrow_bold});
  background-size: 7px 13px;
  background-position: right 12px center;
  top: calc(329 / 697 * 100vh + 80px);
  text-transform: uppercase;
  background-size: 14px;
  background-repeat: no-repeat;
  white-space: nowrap;
  @media(min-width: 768px){
    top: calc(485 / 810 * 100vh);
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: transform, box-shadow;
    transition-duration: 150ms;
    &:hover{
      transform: translateX(-50%) scale3d(1.03,1.03,1.03);
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.12);
    }

  }
`
const ButtonCallToAction= styled(GatsbyLink)`
  z-index: 3;
  border-radius: 6px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.mi_white};
  color: ${({ theme }) => theme.mi_black};

  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 24px;
  margin: 36px 24px 36px 0;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
  .mi-arrow-cta{
    display: block;
    width: 18px;
    height: 10px;
    align-self: center;
    background-image: url(${arrow_cta});
    background-size: contain;
    background-repeat: no-repeat;

    align-self: flex-end;
    margin-bottom: 5px;
    margin-left: 8px;
  }
  @media(min-width: 768px){
    max-width: 302px;
    margin-left: 0;
    margin-top: 36px;
    margin-bottom: 100px;

    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: transform, box-shadow;
    transition-duration: 150ms;
    &:hover{
      transform: scale3d(1.02,1.02,1.02);
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.12);
    }
  }
`

const Left = styled.div`
  @media(min-width: 768px){
    flex: 5 0 41.66%;
  }`
const Right = styled.div`
  @media(min-width: 768px){
    flex: 7 0 56%;
  }`

export { Wrapper, Right, Left, ButtonCallToAction, ButtonMore, HomeContent, SubSubHeadline,  SubHeadline, MainHeadline }
