import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "start/coffee-man@3x.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 738) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const MuffinFluid = styled(BackgroundSection)`

z-index: 2;
position: absolute !important;
background-size: contain;
background-position: center right;
background-repeat: no-repeat;
width: calc(96 / 375 * 100vw);
height: calc(96 / 375 * 100vw / 96 * 134);
top: calc(69 / 697 * 100vh);
right: calc(23 / 375 * 100vw);

@media(min-width: 768px){
  display: flex;
  width: calc(246 / 1440 * 100vw);
  height: calc(292 / 1440 * 100vw / 292 * 305);
  top: calc(118 / 810 * 100vh);
  right: calc(29 / 1440 * 100vw);
}
`

export default MuffinFluid

