import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home/dog-door@3x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1212) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const DogFluid = styled(BackgroundSection)`
display: none;
z-index: 2;
position: absolute !important;
background-size: contain;
background-position: center right;
background-repeat: no-repeat;

@media(min-width: 768px){
  display: flex;
  width: 320px;
  height: 291px;  
}
`

export default DogFluid

